import React from 'react';

const GithubIcon = (props) => {
  return (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.739.105C6.179.105.05 6.468.05 14.318c0 6.28 3.922 11.607 9.361 13.486.685.132.936-.308.936-.684 0-.338-.013-1.458-.019-2.646-3.808.86-4.612-1.677-4.612-1.677-.622-1.642-1.52-2.08-1.52-2.08-1.242-.881.094-.863.094-.863 1.375.1 2.098 1.464 2.098 1.464 1.221 2.173 3.203 1.545 3.984 1.182.123-.918.477-1.546.869-1.9-3.04-.36-6.237-1.579-6.237-7.025 0-1.551.535-2.82 1.41-3.815-.141-.358-.61-1.803.133-3.76 0 0 1.15-.383 3.766 1.456a12.667 12.667 0 013.426-.478 12.7 12.7 0 013.43.478c2.612-1.839 3.76-1.457 3.76-1.457.745 1.958.276 3.403.134 3.761.878.996 1.409 2.264 1.409 3.815 0 5.46-3.202 6.661-6.25 7.013.49.441.928 1.306.928 2.632 0 1.902-.016 3.432-.016 3.9 0 .379.246.822.94.682 5.436-1.881 9.354-7.207 9.354-13.484 0-7.85-6.13-14.213-13.689-14.213z"
        fill={props.fill}
        fillRule="nonzero"
      />
    </svg>
  );
};

GithubIcon.defaultProps = {
  fill: '#000',
};

export default GithubIcon;
